import React from "react";
import "../style/_dashboard.scss";
import Ellipse from "../Assets/Icon metro-user.svg";
import menu1 from "../Assets/Group 187.png";
import menu2 from "../Assets/Group 185.png";
import menu3 from "../Assets/Group 186.png";
import menu4 from "../Assets/Group 182.png";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { smartContract } from "../store/User/action";

class UserSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      nationalId: false,
      addressProof: false,
      Licence_CR: false,
      incorporation: false,
      moaAndAoa: false,
      incumberency: false,
      smartContract: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.docDetail !== this.props.docDetail) {
      // console.log(this.props.docDetail)
      if (Array.isArray(this.props.docDetail?.data?.data)) {
        this.props.docDetail?.data?.data.map((each) => {
          console.log(each.doc_name);
          if (each?.doc_name == "national_id") {
            this.setState(() => ({
              nationalId: true,
            }));
          }

          if (each?.doc_name == "address_proof") {
            this.setState(() => ({
              addressProof: true,
            }));
          }
          if (each?.doc_type == "smartContractDoc") {
            this.setState(() => ({
              smartContract: true,
            }));
          }
        });
      }
    }

    if (prevProps.compDocDetail !== this.props.compDocDetail) {
      // console.log(this.props.compDocDetail)
      if (Array.isArray(this.props.compDocDetail?.data?.data)) {
        this.props.compDocDetail?.data?.data.map((each) => {
          this.setState(() => ({
            company_doc: true,
          }));
          if (each?.doc_name === "Licence_CR") {
            this.setState(() => ({
              Licence_CR: true,
            }));
          }
          if (each?.doc_name === "Incorporation") {
            this.setState(() => ({
              incorporation: true,
            }));
          }
          if (each?.doc_name === "Incumberency") {
            this.setState(() => ({
              incumberency: true,
            }));
          }
          if (each?.doc_name === "MoaAndAoa") {
            this.setState(() => ({
              moaAndAoa: true,
            }));
          }
        });
      }
    }
  }

  handleRedirectMenu = (data) => {
    console.log(this.state);
    if (data === "/user/legal_document/company_profile") {
      if (
        this.state.incorporation ||
        this.state.Licence_CR ||
        this.state.moaAndAoa ||
        this.state.incumberency ||
        this.state.company_doc
      ) {
        this.props.history.push({
          pathname: "/user/legal_document/documents",
          state: {
            incorporation: this.state.incorporation,
            Licence_CR: this.state.Licence_CR,
            moaAndAoa: this.state.moaAndAoa,
            incumberency: this.state.incumberency,
          },
        });
      } else {
        this.props.history.push(data);
      }
    } else if (data === "/user/individual_document/personal_profile") {
      if (this.state.nationalId || this.state.addressProof) {
        this.props.history.push(
          "/user/individual_document/blockchain_document"
        );
      } else {
        this.props.history.push(data);
      }
    } else if (data === "/user/smart_contract/basic_details") {
      if (this.state.smartContract) {
        this.props.history.push({
          pathname: "/user/smart_contract/documents",
          state: {
            smartContract: this.state.smartContract,
          },
        });
      } else {
        this.props.history.push(data);
      }
    } else {
      this.props.history.push(data);
    }
  };

  render() {
    const data = window.location.href.split("/")[4];
    return (
      <div className="sideBar">
        <div className="row img-area">
          <img alt="" src={Ellipse} />
          <p>
            <div>{localStorage.getItem("username")}</div>{" "}
            <span>Blockchain Registry</span>
          </p>
          {/* <p>{localStorage.getItem('username')}<br /> <span>Blockchain Registry</span></p> */}
        </div>
        <div className="menu_area" style={{ fontSize: "1.3rem" }}>
          <div
            className="row"
            style={{ marginTop: "15", cursor: "pointer" }}
            onClick={() => this.handleRedirectMenu("/user/Dashboard")}
          >
            {/* <img alt='' src={menu1}/> */}
            <p
              style={{
                color: data === "Dashboard" ? "white" : "#aaaaaa",
                margin: "0%",
              }}
            >
              Dashboard
            </p>
          </div>
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.handleRedirectMenu("/user/legal_document/company_profile")
            }
          >
            {/* <img alt='' src={menu2}/> */}
            <p
              style={{
                color: data === "Company_list" ? "white" : "#aaaaaa",
                margin: "0%",
              }}
            >
              Legal / Corporate Documents
            </p>
          </div>
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.handleRedirectMenu(
                "/user/individual_document/personal_profile"
              )
            }
          >
            {/* <img alt='' src={menu3}/> */}
            <p
              style={{
                color: data === "Company_list" ? "white" : "#aaaaaa",
                margin: "0%",
              }}
            >
              Individual / Personal Documents
            </p>
          </div>
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.handleRedirectMenu("/user/smart_contract/basic_details")
            }
          >
            {/* <img alt='' src={menu4}/> */}
            <p
              style={{
                color: data === "Dashboward" ? "white" : "#aaaaaa",
                margin: "0%",
              }}
            >
              Smart Contract
            </p>
          </div>
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() => this.handleRedirectMenu("/user/vault/list")}
          >
            {/* <img alt='' src={menu4}/> */}
            <p
              style={{
                color: data === "vault" ? "white" : "#aaaaaa",
                margin: "0%",
              }}
            >
              My Vault
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docDetail: state.user.personalDoc,
    docDetail_err: state.user.personalDoc_err,
    compDocDetail: state.user.companyDoc,
    compDocDetail_err: state.user.companyDoc_err,
  };
};

export default connect(mapStateToProps)(UserSideBar);
