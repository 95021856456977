import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import ForgetPassword from "./Home/ForgetPassword.js";
import ResetPassword from "./Home/ResetPassword.js";
import SignUpScreen from "./Home/SignUp";
import HomeScreen from "./Home/index";
import GeneralLayout from "./Layout/GeneralLayout/index";
import Header1 from "./Layout/Header/Header1";
import Header from "./Layout/Header/index";
import RouteWithLayout from "./Layout/RouteWithLayout";

import ShowPdf from "./Screen/ShowPdf";

import CompanyProfile from "./Screen/User/LegalDocuments/CompanyProfile";
import LegalDocuments from "./Screen/User/LegalDocuments/LegalDocuments";
import SupportingInfo from "./Screen/User/LegalDocuments/SupportingInfo";
import UserDashboard from "./Screen/User/UserDashboard.js";

import PersonalDegreeDocument from "./Screen/User/IndividualDocuments/PersonalDegreeDocument.js";
import PersonalProfile from "./Screen/User/IndividualDocuments/PersonalProfile.js";
import PersonalSupportingDocument from "./Screen/User/IndividualDocuments/PersonalSupportingDocument.js";

import SmBasicDetails from "./Screen/User/SmartContract/SmBasicDetails.js";
import SmDocuments from "./Screen/User/SmartContract/SmDocuments.js";

import DocumentList from "./Screen/User/MyVault/documentList.js";
import MyVault from "./Screen/User/MyVault/MyVault.js";
import ShareDocument from "./Screen/User/MyVault/shareDoc.js";

import { ToastContainer } from "react-toastify";
import "./style/_CompanyOnboard.scss";
import "./style/_comapnyList.scss";
import "./style/_dashboard.scss";
import "./style/_generalLayout.scss";
import "./style/_login.scss";
import StripeCheckout from "./Component/CheckoutForm.js";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <RouteWithLayout
          layout={GeneralLayout}
          path="/"
          exact
          component={HomeScreen}
        />
        <RouteWithLayout
          layout={GeneralLayout}
          path="/SignUp"
          component={SignUpScreen}
        />
        <RouteWithLayout
          layout={GeneralLayout}
          path="/forget_password"
          component={ForgetPassword}
        />
        <RouteWithLayout
          layout={GeneralLayout}
          path="/reset"
          component={ResetPassword}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/Dashboard"
          component={UserDashboard}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/legal_document/company_profile"
          component={CompanyProfile}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/legal_document/supporting_info"
          component={SupportingInfo}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/legal_document/documents"
          component={LegalDocuments}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/individual_document/personal_profile"
          component={PersonalProfile}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/individual_document/supporting_document"
          component={PersonalSupportingDocument}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/individual_document/blockchain_document"
          component={PersonalDegreeDocument}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/smart_contract/basic_details"
          component={SmBasicDetails}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/smart_contract/documents"
          component={SmDocuments}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/vault/list"
          component={DocumentList}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/vault/each_document"
          component={MyVault}
        />

        <RouteWithLayout
          layout={Header}
          path="/user/vault/shareDocument"
          component={ShareDocument}
        />
        <RouteWithLayout
          layout={Header}
          path="/user/payment"
          component={StripeCheckout}
        />
        {/* <RouteWithLayout layout={Header} path="/Face_Match" component={FaceMatchScreen} /> */}
        <RouteWithLayout
          layout={Header1}
          path="/document/:company_id/:hash_value/:type"
          component={ShowPdf}
        />
      </Switch>
    </Router>
  );
}

export default App;
