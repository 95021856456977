import React from "react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { SiHiveBlockchain } from "react-icons/si";
import verified from "../Assets/blockchain.jpg";
import trademark from "../Assets/tradeMark.png";
import logo from "../Assets/Logo-08.png";
import { withRouter } from "react-router-dom";
// import { Viewer } from '@react-pdf-viewer/core';
import { PDFDocument, rgb } from "pdf-lib";
import QRCode from "qrcode";

class ShowPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: false,
      hashvalue: "",
      companyId: "",
      txId: "",
      isVerified: false,
      isMismatched: false,
      timeStamp: "",
      doc_id: "",
      doc_name: "",
    };
  }

  componentDidMount() {
    console.log(this.props.match.params);

    if (this.props.location.pathname !== undefined) {
      const url = this.props.location.pathname.split("/");
      console.log(url);
      const UrlData = url[4];

      // if(this.state.hashvalue && this.state.companyId){
      axios
        .get(
          // `http://10.10.10.114:8084/${
          //   this.props.match.params.type == "personal"
          //     ? "get_particular_personal_doc_details"
          //     : "get_particular_doc_details"
          // }/${this.props.match.params.company_id}/${
          //   this.props.match.params.hash_value
          // }`
          `https://node.blockchainregistry.ai/${
            this.props.match.params.type == "personal"
              ? "get_particular_personal_doc_details"
              : "get_particular_doc_details"
          }/${this.props.match.params.company_id}/${
            this.props.match.params.hash_value
          }`
        )
        .then((res) => {
          console.log(res?.data?.data);
          this.setState({
            hashvalue: res?.data?.data?.hash,
            txId: res?.data?.data?.txID,
            companyId: res?.data?.data?.company_id,
            isVerified: res?.data?.data?.isVerified,
            isMismatched: res?.data?.data?.isMismatched,
            timeStamp: res?.data?.data?.updatedAt,
            doc_id: res?.data?.data?.doc_id,
            doc_name: res?.data?.data?.doc_name,
          });

          this.generatePdf();
        })
        .catch((err) => {});
      // }
    }
  }

  generatePdf() {
    const modifyPDF = async () => {
      // const pdfUrl = `http://10.10.10.114:8084/view_document/${this.state?.doc_id}`;
      const pdfUrl = `https://node.blockchainregistry.ai/view_document/${this.state?.doc_id}`;
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pages = pdfDoc.getPages();

      this.setState({
        numPages: pdfDoc.getPageCount(),
      });

      const qrCodeDataUrl = await QRCode.toDataURL(window.location.href);
      const qrCodeImage = await pdfDoc.embedPng(qrCodeDataUrl);

      const imageBytes = await fetch(logo).then((res) => res.arrayBuffer());
      const logoImage = await pdfDoc.embedPng(imageBytes);

      const { width, height } = pages[0].getSize();

      pages.forEach((page) => {
        const { width, height } = page.getSize();

        // Calculate dimensions dynamically
        let logoWidth = width * 0.2; // 20% of page width
        let logoHeight = height * 0.15; // 15% of page height

        if (width > height) {
          logoWidth = width * 0.15;
          logoHeight = height * 0.2;
        }

        const qrCodeSize = Math.min(width, height) * 0.1; // 10% of the smaller dimension
        const textSize = Math.min(width, height) * 0.015; // 1.5% of the smaller dimension

        page.drawImage(logoImage, {
          x: width - logoWidth - 20, // Add some padding
          y: 0, // Padding from the bottom
          width: logoWidth,
          height: logoHeight,
        });

        page.drawImage(qrCodeImage, {
          x: 20,
          y: 20,
          width: qrCodeSize,
          height: qrCodeSize,
        });

        page.drawText(`Verified BlockchainId- ${this.state.hashvalue}`, {
          x: 20 + qrCodeSize + 10,
          y: 20,
          size: textSize,
          color: rgb(0, 0.53, 0.71),
        });

        page.drawText(`This document is verified by Blockchain Registry`, {
          x: 20 + qrCodeSize + 10,
          y: textSize * 4,
          size: textSize,
          color: rgb(1, 0.53, 0.71),
        });

        page.drawText(
          `Verify the document by scanning the QR code. Any correction will render the document invalid`,
          {
            x: 20 + qrCodeSize + 10,
            y: textSize * 6,
            size: textSize,
            color: rgb(1, 0.53, 0.71),
          }
        );
      });

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      this.setState({
        modifiedPDF: URL.createObjectURL(modifiedPdfBlob),
      });

      console.log(URL.createObjectURL(modifiedPdfBlob));
    };

    modifyPDF();
  }

  onDocumentLoadSuccess = () => {
    console.log(this.state.numPages);
  };

  render() {
    const isMobile = window.innerWidth < 768;

    return (
      <Fragment>
        <div
          style={{
            overflowX: "hidden",
          }}
        >
          <div className="row">
            <center>
              <div style={{ padding: "3rem" }}>
                <div>
                  {" "}
                  <img src={verified} style={{ width: "150px" }} />
                  <label
                    style={{
                      marginLeft: "20px",
                      fontSize: "25px",
                      color: "#23898e",
                    }}
                  ></label>
                </div>
                <br />
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "20px",
                    overflowWrap: "anywhere",
                  }}
                >
                  {" "}
                  Blockchain Transaction Identifier &nbsp;: &nbsp;
                  <div style={{ fontSize: "16px", color: "" }}>
                    {this.state?.txId}
                  </div>
                </label>
              </div>
            </center>
            <div
              style={{ width: "100%" }}
              className={
                isMobile
                  ? "d-flex flex-column"
                  : "d-flex justify-content-center"
              }
            >
              {/* {this.state.certificate !== undefined &&
                                <embed src={`data:application/pdf;base64,${this.state.certificate?.file}`} height={650}
                                width={600}/>
                            } */}

              {isMobile ? (
                <>
                  <div className="m-auto">
                    <label
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      <div className="m-3">Blockchain Verified On:</div>
                      <div
                        className="py-1 px-4 d-flex flex-column m-3"
                        style={{
                          backgroundColor: "black",
                          color: "lightgrey",
                          fontSize: "18px",
                          borderRadius: "10px",
                        }}
                      >
                        <label>
                          Date :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(0, 10)}
                          </span>
                        </label>
                        <label>
                          Time :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(11, 24)}
                          </span>
                        </label>
                      </div>
                    </label>
                  </div>
                  <div className="m-auto w-100 position-relative">
                    {/* <iframe
                      src={this.state.modifiedPDF}
                      style={{
                        width: "100%",
                        height: "650px",
                        border: "2px solid black",
                      }}
                    ></iframe> */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={this.state.modifiedPDF}
                        download={`${this.state.doc_name}.pdf`}
                        style={{
                          textDecoration: "none",

                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          backgroundColor: "black",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <iframe
                    src={this.state.modifiedPDF}
                    style={{ width: "40%", height: "650px", border: "none" }}
                  ></iframe>
                  {/* <div
                    className="position-relative"
                    style={{
                      height: "500px",
                      overflowY: "scroll",
                      border: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    {console.log("Rendering Document with pages")}
                    <Document
                      file={this.state.modifiedPDF}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                      {Array.from(
                        new Array(this.state.numPages),
                        (el, index) => {
                          const key = `page_${index + 1}`;
                          console.log("Rendering Page with key:", key);
                          return <Page key={key} pageNumber={index + 1} />;
                        }
                      )}
                    </Document>
                  </div> */}
                  <div className="m-3">
                    <label
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      <div className="m-3">Blockchain Verified On:</div>
                      <div
                        className="py-1 px-4 d-flex flex-column m-3"
                        style={{
                          backgroundColor: "black",
                          color: "lightgrey",
                          fontSize: "18px",
                          borderRadius: "10px",
                        }}
                      >
                        <label>
                          Date :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(0, 10)}
                          </span>
                        </label>
                        <label>
                          Time :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(11, 24)}
                          </span>
                        </label>
                      </div>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ShowPdf;
