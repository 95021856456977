import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../../Assets/Group 32.png";
import chain from "../../../Assets/Group 341.png";
import PdfRender from "../../../Component/PdfRender";
import UserSignBar from "../../../Component/UserSignBar";
import trademark from "../../../Assets/Logo-07.png";
// import { getCertificate,verifyDocument } from '../../../../store/User/action';
import { toast } from "react-toastify";
import { ImCross } from "react-icons/im";
import pdf from "../../../Assets/Group 14.svg";
import { reUploadDocument } from "../../../store/User/action";

// import load from '../../../Assets/load'

class MyVault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: false,
      certificate: undefined,
      isVerified: false,
      isMismatched: false,
      sendingVerification: false,
    };
  }
  componentDidMount() {
    console.log(this.props?.history?.location?.state);
    if (
      this.props?.history?.location?.state?.certificateName === "Licence_CR"
    ) {
      this.setState({
        name: "Licence_CR",
      });
    } else if (
      this.props?.history?.location?.state?.certificateName === "national_id"
    ) {
      this.setState({
        name: "National ID",
      });
    } else if (
      this.props?.history?.location?.state?.certificateName === "address_proof"
    ) {
      this.setState({
        name: "Address Proof",
      });
    } else {
      this.setState({
        name: this.props?.history?.location?.state?.certificateName,
      });
    }
  }

  componentDidUpdate(prev) {
    if (prev.reUpload !== this.props.reUpload) {
      console.log(prev.reUpload);
      toast.success(this.props.reUpload?.data?.message);
      this.setState({
        sendingVerification: false,
      });
      this.props.history.goBack();
    }

    if (prev.reUploadErr !== this.props.reUploadErr) {
      console.log(this.props.reUploadErr);
      if (this.props.reUploadErr?.response?.data?.error === "jwt expired") {
        this.props.history.push("/");
      }
      toast.error(
        this.props.reUploadErr?.response?.data?.message ||
          this.props.reUploadErr?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingVerification: false,
      });
    }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  generateUrl = () => {
    const url = new URL(window.location.href);
    // const domainWithPort = `https://gateway.pinata.cloud/ipfs/${this.props.history.location.state?.certificateId ?? ""}`;
    var domainWithPort = "";
    if (this.props.history.location.state?.data?.UID) {
      domainWithPort = `${url.protocol}//${url.host}/document/${
        localStorage.getItem("userId") ||
        this.props.history.location.state?.data?.userId
      }/${this.props.history.location.state?.certificateId ?? ""}/personal`;
    } else {
      domainWithPort = `${url.protocol}//${
        url.host
      }/document/${localStorage.getItem("companyId")}/${
        this.props.history.location.state?.certificateId ?? ""
      }/company`;
    }
    console.log(domainWithPort);

    return domainWithPort;
  };

  handleShare = () => {
    const domainWithPort = this.generateUrl();
    this.props.history.push({
      pathname: "/user/vault/shareDocument",
      state: {
        url: domainWithPort,
        data: this.props?.history?.location?.state?.data,
      },
    });
  };

  handlePassport = (e) => {
    const maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

    if (e.target.files[0].size > maxFileSize) {
      // alert("The file size exceeds 20 MB. Please upload a smaller document.");
      alert(
        "The file size exceeds 20 MB. Please upload a smaller document. You can use the following tools to compress your file:\n\n" +
          "1. Smallpdf: https://smallpdf.com/compress-pdf\n" +
          "2. Compress2Go: https://www.compress2go.com/"
      );
      return;
    }

    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    document.getElementById(`upload-${name}`).value = "";
  };

  handleUpload = () => {
    const userConfirmed = window.confirm(
      "Please check the document's orientation and ensure everything is correct. Click OK to continue or Cancel to go back."
    );

    if (!userConfirmed) {
      return;
    }

    console.log("upload");
    if (this.state.file1 === undefined || this.state.file1 === "") {
      toast.error("Please select file");
      return;
    }
    this.setState({
      sendingVerification: true,
    });
    const formData = new FormData();
    formData.append(this.state.name, this.state.file1);
    console.log(
      formData,
      this.state.name,
      this.props.history?.location?.state?.data?._id
    );
    const data = {
      document_id: this.props.history?.location?.state?.data?._id,
      body: formData,
    };
    this.props.reUploadDocument(data);
  };

  render() {
    console.log(this.props.history?.location?.state?.data.rejection_remark);
    return (
      <div className="listed-page">
        <div className="lg-page-banner">
          <UserSignBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="pageData">
            <div className="first-row">
              <img src={logo} />
              <p>
                {this.state.name === "Incumberency"
                  ? "Incumbency"
                  : this.state.name}{" "}
                <br />
                <span>
                  Verifying certificate of Incorporation by using Blockchain
                  based verification system
                </span>
              </p>
            </div>
            <div className="row second-row">
              <div className="col-lg-6 position-relative">
                {/* <iframe src="../Assets/Trade-00000906195-Process.pdf" style={{width: '100%',height: '100%',border: "none"}}></iframe> */}
                {/* <embed src="../Assets/Trade-00000906195-Process.pdf" width="300px" height="500px" /> */}
                <PdfRender
                  certificate={this.props.history.location.state?.data?.doc_id}
                  verified={this.props.history.location.state?.isVerified}
                  url={this.generateUrl()}
                />
                {/* <div className='position-absolute' style={{width:'100px',height:'100px', top:'10px', right:'60px', transform:'rotate(-30deg)'}}><img src={trademark} width={100} height={100} /></div> */}
              </div>
              <div className="col-lg-6">
                <div className="col-first">
                  {/* <img src ={logod} /> */}
                  <p>
                    {this.props.history?.location?.state?.data?.company_name}{" "}
                    <br />
                    <span id="span">
                      Know your details in a quick overview,
                    </span>{" "}
                    <br />
                    {/* <span id="span1">Onboarded On {this.props.history?.location?.state?.data?.company_registration_date}</span> &nbsp; &nbsp;  */}
                    <span id="span2">
                      {this.props.history?.location?.state?.data?._id}
                    </span>
                    <br />
                    <span id="span3">
                      {this.props.history?.location?.state?.data?.txID}
                    </span>
                  </p>
                </div>
                <div className="col-second">
                  <img src={chain} style={{ marginTop: "-1%" }} />
                  <p>
                    {this.props.history?.location?.state?.data?.isVerified
                      ? "Verified by BlockChain"
                      : this.props.history?.location?.state?.data?.isMismatched
                      ? "Mismatched by BlockChain"
                      : "Not verified by BlockChain"}
                  </p>
                </div>
                <div className="row col-third">
                  <div className="col-md-3">
                    <p>Status</p>
                  </div>
                  <div className="col-md-9">
                    {/* <p>: Document Verified successfully</p> */}
                    <p>
                      {this.props.history?.location?.state?.data?.isVerified
                        ? "Document Verified successfully"
                        : this.props.history?.location?.state?.data
                            ?.isMismatched
                        ? "Document Mismatched"
                        : "Document not verified"}
                    </p>
                  </div>
                </div>
                <div className="row col-third1">
                  <div className="col-md-3">
                    <p>Time Stamp</p>
                  </div>
                  <div className="col-md-9">
                    <p>
                      {this.props?.history?.location?.state?.data?.createdAt}
                    </p>
                  </div>
                </div>

                <div className="row col-third1">
                  {this.props.history?.location?.state?.data?.isVerified && (
                    <>
                      <div className="col-md-3">
                        <p>Verified by</p>
                      </div>
                      <div className="col-md-9">
                        <p>: BlockChain Registry</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-fourth">
                  {/* {this.state.verified ? <button disabled> Verification</button> : <button onClick={this.handleSendForVerification}>Send For Verification</button>} */}
                  {this.props.history?.location?.state?.data?.isVerified ? (
                    <button onClick={this.handleShare}> Share</button>
                  ) : this.props.history?.location?.state?.data
                      ?.isMismatched ? (
                    <button disabled style={{ cursor: "not-allowed" }}>
                      Mismatch
                    </button>
                  ) : this.props.history?.location?.state?.data?.isRejected ? (
                    <div>
                      <div>
                        Rejection Remark : <span> </span>
                        <span style={{ color: "red" }}>
                          {
                            this.props.history?.location?.state?.data
                              ?.rejection_remark
                          }
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          fontSize: "0.9rem",
                        }}
                      >
                        <p style={{ margin: "0px", padding: "0px" }}>
                          Valid Format PDF.
                        </p>
                        <input
                          type="file"
                          id="upload-file1"
                          name="file1"
                          accept=".pdf"
                          hidden
                          onChange={this.handlePassport}
                        />

                        <label
                          for="upload-file1"
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                        >
                          Upload
                        </label>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                          style={{
                            cursor: this.state.sendingVerification
                              ? "none"
                              : "pointer",
                            width: "50%",
                            margin: "10px 0px",
                          }}
                          onClick={this.handleUpload}
                        >
                          {this.state.sendingVerification
                            ? "Re-uploading"
                            : "Re-upload"}
                        </button>
                      </div>
                      {this.state.errors.file1 !== undefined && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            marginTop: "0px",
                          }}
                        >
                          {this.state.errors.file1}
                        </p>
                      )}
                      {this.state.file1 !== undefined && (
                        <div
                          className="paradiv"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <img src={pdf} />
                          <a id="para">{this.state.file1.name}</a>{" "}
                          <ImCross
                            style={{
                              fontSize: "10px",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => this.cleanFile("file1")}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <button disabled style={{ cursor: "not-allowed" }}>
                      Waiting to be Verified
                    </button>
                  )}
                  {/* <button onClick={this.handleSendForVerification}>Send For Verification</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    reUpload: state.user.reUploadDoc,
    reUploadErr: state.user.reUploadDoc_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reUploadDocument,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVault);
