import React from "react";
import { ImCross } from "react-icons/im";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import building from "../../../Assets/Group 11.svg";
import pdf from "../../../Assets/Group 14.svg";
import one from "../../../Assets/Group 3.svg";
import two from "../../../Assets/Group 4.svg";
import three from "../../../Assets/Group 5.svg";
import load from "../../../Assets/load";
import IndividualDocumentSidebar from "../../../Component/IndividualDocumentSideBar";
import { supportingDocuments } from "../../../store/User/action";

class PersonalSupportingDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loadState: false,
    };
  }

  componentDidMount() {
    // if(localStorage.getItem('username')===null){
    //     this.props.history.push('/');
    // }
  }

  componentDidUpdate(prev) {
    if (prev.fail_documentUpload !== this.props.fail_documentUpload) {
      console.log(this.props.fail_documentUpload);
      toast.error(this.props?.fail_documentUpload?.response?.data?.message);
      this.setState({
        loadState: false,
      });
      if (
        this.props.fail_documentUpload?.response?.data?.error === "jwt expired"
      ) {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
    }

    if (prev.succ_documentUpload !== this.props.succ_documentUpload) {
      console.log(this.props.succ_documentUpload);
      this.props.history.push({
        pathname: "/user/individual_document/blockchain_document",
        state: {
          individualDocId: this.props.history.location.state.individualDocId,
        },
      });
    }
  }

  handlePassport = (e) => {
    const maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

    if (e.target.files[0].size > maxFileSize) {
      alert(
        "The file size exceeds 20 MB. Please upload a smaller document. You can use the following tools to compress your file:\n\n" +
          "1. Smallpdf: https://smallpdf.com/compress-pdf\n" +
          "2. Compress2Go: https://www.compress2go.com/"
      );
      return;
    }

    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    document.getElementById(`upload-${name}`).value = "";
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    //   if (this.state.file3 === undefined || this.state.file3 === '') {
    //     formIsValid = false;
    //     errors.file3 = 'This is a required field';
    //   }

    if (this.state.file1 === undefined || this.state.file1 === "") {
      formIsValid = false;
      errors.file1 = "This is a required field";
    }
    if (this.state.file2 === undefined || this.state.file2 === "") {
      formIsValid = false;
      errors.file2 = "This is a required field";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = () => {
    const userConfirmed = window.confirm(
      "Please check the document's orientation and ensure everything is correct. Click OK to continue or Cancel to go back."
    );

    if (!userConfirmed) {
      return;
    }

    if (this.handleValidation()) {
      this.setState({
        loadState: true,
      });
      var min = 1;
      var max = 1000;
      var rand = Math.round(min + Math.random() * (max - min));

      console.log(rand);
      localStorage.setItem("random", rand);

      const formData = new FormData();
      formData.append("address_proof", this.state.file1);
      formData.append("national_id", this.state.file2);
      // formData.append('issuing_authority', this.state.file3);

      this.props.supportingDocuments({
        body: formData,
        individualDocId: this.props.history.location.state.individualDocId,
      });
    }
    //    this.props.history.push('/user/individual_document/blockchain_document');
  };

  render() {
    console.log(this.state);
    return (
      <div className="Onboard-page">
        <ToastContainer />
        <div className="lg-page-banner">
          <IndividualDocumentSidebar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="row first-row" style={{ display: "flex" }}>
            <label>Individual Document</label>
          </div>
          {/* <div className='row'>
                        <p style={{marginLeft:'1.5%'}}>Complete the Onboarding Process , kindly read the user manual for any assistance.</p>
                    </div> */}
          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Personal Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Supporting Document</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p>Blockchain Document</p>
            </div>
          </div>
          <div className="row third-row">
            <div className="col-md-6">
              <img src={building} />
              <p>
                Supporting Document <br />
                <span>
                  Upload documents and complete the onboarding (Kindly scan both
                  sides of document*)
                </span>
              </p>
            </div>
          </div>

          <div className="row sixth-row">
            <div className="col-md-4">
              <p>
                Address Proof{" "}
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF.</p>
                <input
                  type="file"
                  id="upload-file1"
                  name="file1"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file1">Upload</label>
              </div>
              {this.state.errors.file1 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file1}
                </p>
              )}
              {this.state.file1 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file1.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file1")}
                  />
                </div>
              )}
            </div>
            <div className="col-md-4">
              <p>
                National ID{" "}
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF.</p>
                <input
                  type="file"
                  id="upload-file2"
                  name="file2"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file2">Upload</label>
              </div>
              {this.state.errors.file2 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file2}
                </p>
              )}
              {this.state.file2 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file2.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file2")}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    succ_documentUpload: state.user.supportingDoc,
    fail_documentUpload: state.user.supportingDoc_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      supportingDocuments,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalSupportingDocument);
