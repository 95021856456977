import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  CERTICATE_DETAIL,
  CERTICATE_DETAIL_ERR,
  COMPANY_DETAIL,
  COMPANY_DETAIL_ERR,
  POST_ONBOARDING_STORE,
  POST_ONBOARDING_STORE_ERR,
  POST_SHAREHOLDER_STORE,
  POST_SHAREHOLDER_STORE_ERR,
  REGISTER_API,
  REGISTER_API_ERR,
  SIGN_IN_API,
  SIGN_IN_API_ERR,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_ERR,
  DASHBOARD_DETAIL,
  DASHBOARD_DETAIL_ERR,
  DASHBOARD_COMPANIES_DETAIL,
  DASHBOARD_COMPANIES_DETAIL_ERR,
  DASHBOARD_COMPANY_DOC,
  DASHBOARD_COMPANY_DOC_ERR,
  FORGET_PASSWORD_SEND,
  FORGET_PASSWORD_SEND_ERR,
  OTP_VERIFICATION,
  OTP_VERIFICATION_ERR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERR,
  VERIFICATION_DOC,
  VERIFICATION_DOC_ERR,
  PERSONAL_PROFILE,
  PERSONAL_PROFILE_ERR,
  SUPPORTING_DOC,
  SUPPORTING_DOC_ERR,
  DEGREE_CERTIFICATE,
  DEGREE_CERTIFICATE_ERR,
  SMART_CONTRACT,
  SMART_CONTRACT_ERR,
  PERSONAL_DOC_ERR,
  PERSONAL_DOC,
  COMPANY_DOC_ERR,
  COMPANY_DOC,
  RE_UPLOAD_ERR,
  RE_UPLOAD,
} from "./types";

const MySwal = withReactContent(Swal);

//dev url
// const Url = "http://10.10.10.114:8084/";
export const stripePublishKey =
  "pk_test_51QmZaYGHjXJ2ijZFuOa9zvtYuSW3RasMoCdtjtIZpgQFwnuEmn7ULWRvpACuSwuhdGntyErNt0jioJ7zjY2jSzuO00mWlPjNdc";

//prod url
const Url = "https://node.blockchainregistry.ai/";

//sign up API call
export const postSignUpApi = (data) => (dispatch) => {
  axios
    .post(Url + "api/signUp", data)

    .then((res) => {
      console.log(res);
      dispatch({ type: REGISTER_API, payload: res });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: REGISTER_API_ERR, payload: err });
    });
};

//sign in API call
export const postSignInApi = (data) => (dispatch) => {
  axios
    .post(Url + "api/login", data)

    .then((res) => {
      console.log(res);
      dispatch({ type: SIGN_IN_API, payload: res });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: SIGN_IN_API_ERR, payload: err });
    });
};

//forget password send mail
export const postForgetPasswordSendMail = (data) => (dispatch) => {
  axios
    .post(Url + "api/forgot_password", data)

    .then((res) => {
      console.log(res);
      dispatch({ type: FORGET_PASSWORD_SEND, payload: res });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FORGET_PASSWORD_SEND_ERR, payload: err });
    });
};

//otp verification
export const otpVerification = (data) => (dispatch) => {
  axios
    .post(Url + "api/forgot_password_otp_verification", data)

    .then((res) => {
      console.log(res);
      dispatch({ type: OTP_VERIFICATION, payload: res });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: OTP_VERIFICATION_ERR, payload: err });
    });
};

//reset password
export const resetPassword = (data) => (dispatch) => {
  axios
    .put(Url + `api/reset_password/${data.userId}`, data.password)

    .then((res) => {
      console.log(res);
      dispatch({ type: RESET_PASSWORD, payload: res });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: RESET_PASSWORD_ERR, payload: err });
    });
};

//Legal Documents
//onboard company
export const onBoardCompany = (data) => (dispatch) => {
  axios
    .post(
      Url + `api/onboard_company/${localStorage.getItem("userId")}/`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      dispatch({ type: POST_ONBOARDING_STORE, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/onboard_company/${localStorage.getItem('userId')}/`, data, {
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: POST_ONBOARDING_STORE, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: POST_ONBOARDING_STORE_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: POST_ONBOARDING_STORE_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: POST_ONBOARDING_STORE_ERR, payload:err });
      //   }
      // }
      dispatch({ type: POST_ONBOARDING_STORE_ERR, payload: err });
    });
};

//shareholder api
export const postShareHolder = (data) => (dispatch) => {
  axios
    .post(
      Url +
        `api/${localStorage.getItem("userId")}/save_shareHolder_info/${
          data.companyId
        }`,
      data.body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      dispatch({ type: POST_SHAREHOLDER_STORE, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/save_shareHolder_info/${data.companyId}`, data.body,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: POST_SHAREHOLDER_STORE, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: POST_SHAREHOLDER_STORE_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: POST_SHAREHOLDER_STORE_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: POST_SHAREHOLDER_STORE_ERR, payload:err });
      //   }
      // }
      dispatch({ type: POST_SHAREHOLDER_STORE_ERR, payload: err });
    });
};

//upload documents
export const UploadDocumentApi = (data) => (dispatch) => {
  axios
    .post(
      Url +
        `api/${localStorage.getItem("userId")}/upload_company_documents/${
          data.companyId
        }`,
      data.body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: UPLOAD_DOCUMENT, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/upload_company_documents/${data.companyId}`, data.body,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: UPLOAD_DOCUMENT, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: UPLOAD_DOCUMENT_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: UPLOAD_DOCUMENT_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: UPLOAD_DOCUMENT_ERR, payload:err });
      //   }
      // }
      dispatch({ type: UPLOAD_DOCUMENT_ERR, payload: err });
    });
};

//individual Documents
//personal profile
export const personalProfile = (data) => (dispatch) => {
  axios
    .post(
      Url + `api/${localStorage.getItem("userId")}/save_personal_details`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      dispatch({ type: PERSONAL_PROFILE, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/onboard_company/${localStorage.getItem('userId')}/`, data, {
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: PERSONAL_PROFILE, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: PERSONAL_PROFILE_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: PERSONAL_PROFILE_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: PERSONAL_PROFILE_ERR, payload:err });
      //   }
      // }
      dispatch({ type: PERSONAL_PROFILE_ERR, payload: err });
    });
};

//supporting documents
export const supportingDocuments = (data) => (dispatch) => {
  axios
    .put(
      Url +
        `api/${localStorage.getItem("userId")}/save_supporting_doc/${
          data.individualDocId
        }`,
      data.body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: SUPPORTING_DOC, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/upload_company_documents/${data.companyId}`, data.body,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: SUPPORTING_DOC, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: SUPPORTING_DOC_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: SUPPORTING_DOC_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: SUPPORTING_DOC_ERR, payload:err });
      //   }
      // }
      dispatch({ type: SUPPORTING_DOC_ERR, payload: err });
    });
};

//degree certificate
export const degreeCertificate = (data) => (dispatch) => {
  axios
    .put(
      Url +
        `api/${localStorage.getItem("userId")}/save_degree_doc/${
          data.individualDocId
        }`,
      data.body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: DEGREE_CERTIFICATE, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/save_degree_doc/${data.companyId}`, data.body,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: DEGREE_CERTIFICATE, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: DEGREE_CERTIFICATE_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: DEGREE_CERTIFICATE_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: DEGREE_CERTIFICATE_ERR, payload:err });
      //   }
      // }
      dispatch({ type: DEGREE_CERTIFICATE_ERR, payload: err });
    });
};

//smart contract
export const smartContract = (data) => (dispatch) => {
  axios
    .post(
      Url + `api/${localStorage.getItem("userId")}/save_smart_contract_data`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: SMART_CONTRACT, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/save_smart_contract_data`, data.body,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: SMART_CONTRACT, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: SMART_CONTRACT_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: SMART_CONTRACT_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: SMART_CONTRACT_ERR, payload:err });
      //   }
      // }
      dispatch({ type: SMART_CONTRACT_ERR, payload: err });
    });
};

//my Vault
export const personalDocument = (data) => (dispatch) => {
  axios
    .get(Url + `api/${localStorage.getItem("userId")}/get_personal_data`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({ type: PERSONAL_DOC, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.get(Url + `api/${localStorage.getItem('userId')}/get_personal_data`, {
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: PERSONAL_DOC, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: PERSONAL_DOC_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: PERSONAL_DOC_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: PERSONAL_DOC_ERR, payload:err });
      //   }
      // }
      dispatch({ type: PERSONAL_DOC_ERR, payload: err });
    });
};
export const companyDetail = (data) => (dispatch) => {
  axios
    .get(
      Url + `api/${localStorage.getItem("userId")}/get_all_added_companies`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: COMPANY_DETAIL, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.post(Url + `api/${localStorage.getItem('userId')}/get_all_added_companies`,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: COMPANY_DETAIL, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: COMPANY_DETAIL_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: COMPANY_DETAIL_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: COMPANY_DETAIL_ERR, payload:err });
      //   }
      // }
      dispatch({ type: COMPANY_DETAIL_ERR, payload: err });
    });
};
export const companyDocument = (data) => (dispatch) => {
  axios
    .get(
      Url +
        `api/${localStorage.getItem(
          "userId"
        )}/company_documents/${localStorage.getItem("companyId")}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: COMPANY_DOC, payload: res });
    })
    .catch((err) => {
      // if(err.response.data.error==='jwt expired'){
      //   if(localStorage.getItem('refresh_token')){
      //     generateAccessToken()
      //     .then(() => {
      //       axios.get(Url + `api/${localStorage.getItem('userId')}/company_documents/${localStorage.getItem('companyId')}`,{
      //         headers: {
      //           Authorization: "Bearer " + localStorage.getItem('access_token'),
      //         },
      //       })
      //       .then((retryRes) => {
      //         dispatch({ type: COMPANY_DOC, payload: retryRes });
      //       })
      //       .catch((retryErr) => {
      //         dispatch({ type: COMPANY_DOC_ERR, payload: retryErr });
      //       });
      //     })
      //     .catch((refreshErr) => {
      //       console.log("Token refresh failed", refreshErr);
      //       dispatch({ type: COMPANY_DOC_ERR, payload: refreshErr });
      //     });
      //   }else{
      //     dispatch({ type: COMPANY_DOC_ERR, payload:err });
      //   }
      // }
      dispatch({ type: COMPANY_DOC_ERR, payload: err });
    });
};

export const reUploadDocument = (data) => (dispatch) => {
  axios
    .put(
      Url +
        `api/${localStorage.getItem("userId")}/update_rejected_docs/${
          data.document_id
        }`,
      data.body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({ type: RE_UPLOAD, payload: res });
    })
    .catch((err) => {
      dispatch({ type: RE_UPLOAD_ERR, payload: err });
    });
};

//generate access token from refresh token
const generateAccessToken = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("refresh_token");
    localStorage.removeItem("refresh_token");
    axios
      .get(Url + "refresh", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data.data.access_token);
        localStorage.setItem("access_token", res?.data?.data?.access_token);
        resolve(); // Resolve the promise on success
      })
      .catch((err) => {
        console.log(err);
        reject(err); // Reject the promise on error
      });
  });
};

// http://20.25.46.73:8081/api/getfile?docId=QmSMwov36qbjz1VTZka7KRKVNvdBiEnXjEqHtkKaYEL3nq&dmccId_certs=dmcc1811&username=Jagdeesh
